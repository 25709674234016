body {
  margin: 0;
  padding: 0;
  font-family: 'Gaegu', cursive;
}

.app {
  position: relative;
  min-height: 100vh;
  padding: 0px;
  margin: 0px;
}

.background-text {
  position: relative;
  background-color: white;
  color: #FF9900;
  font-size: 18px;
  text-align: justify;
  padding: 0px;
  overflow-y: hidden;
  margin: 0px;
  filter: blur(2px);
  z-index: 1;
}

.text {
  padding: 0px;
  margin: 0px; /* Adjust the padding to leave space for the pinned image */
}

.pinned-image-container {
  position: absolute;
  bottom: 0px;
  left: 50%;
  padding: 0px;
  margin: 0px;
  height: 150px;
  transform: translateX(-50%);
}

.pinned-image {
  width: 150px; /* Adjust the width as per your image size */
}

p {
  margin: 0px;
  padding: 0px;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 128px; /* Increase the border radius for a more rounded shape */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-height: 710px;
}
@media (max-width: 800px) {
  .container {
    min-width: 300px;
    border-radius: 64px;
  }
}

.heading {
  margin-bottom: 32px;
  font-size: 54px;
  text-align: center;
  font-weight: 400;
  padding-left: 110px;
  padding-right: 110px;
}
@media (max-width: 800px) {
  .heading {
    margin-bottom: 8px;
    font-size: 32px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.textbox {
  border: none;
  outline: none;
  padding: 24px;
  border-radius: 48px; /* Increase the border radius for a more rounded shape */
  width: 500px;
  font-size: 24px;
  font-family: 'Gaegu', cursive;
  background-color: lightgray;
  color: black;
  margin-right: 8px;
}

@media (max-width: 800px) {
  .textbox {
    width: 250px;
  }
}
.paste-icon {
  width: 52px;
  height: 52px;
  padding: 11px;
}

@media (max-width: 800px) {
  .paste-icon {
    width: 48px;
    height: 48px;
    padding: 8px;
  }
}
.paste-button {
  background-color: lightgray;
  border-radius: 72px;
  border-width: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button {
  background-color: #ff9f1c;
  color: black;
  font-weight: bold;
  border: none;
  outline: none;
  padding: 20px;
  border-radius: 48px; /* Increase the border radius for a more rounded shape */
  font-size: 28px;
  cursor: pointer;
  font-family: 'Gaegu', cursive;
}
@media (max-width: 800px) {
  .button {
    font-size: 24px;
  }
}

.whitelist-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .whitelist-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.success-text {
  color: #41D434;
  margin: 8px;
  font-size: 32px;
}

@media (max-width: 800px) {
  .success-text {
    font-size: 24px;
  }
}

.failure-text {
  color: red;
  margin: 8px;
  font-size: 32px;
}
@media (max-width: 800px) {
  .successbee {
    height: 100px;
  }
}
